import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import OfficialCommonBtn from '../components/atoms/officialCommonBtn'
import SEO from '../components/atoms/seo'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowFaq01 from '../components/organisms/officialLowFaq01'
import OfficialLowHeader from '../components/organisms/officialLowHeader'
import OfficialGalleryBtnSet from '../components/molecules/officialGalleryBtnSet'
import useRouteParam from '../hooks/useRouteParam'

const Wrapper = styled.main`
  .p-top-data-wrap {
    background: #f9f3c4;
    max-width: 1090px;
    border-radius: 8px;
    margin: -60px auto 60px;
    padding: 20px;
    text-align: center;
    p {
      margin: 0 0 20px;
    }
    button {
      margin: 0;
    }
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * よくあるご質問ページ[サービスサイト質問]
 * @constructor
 */
const ServiceFaqPage = ({ location }: IProps) => {
  const routeParam = useRouteParam('/help')
  return (
    <OfficialLayout location={location} className="p-page-faq">
      <SEO title="よくあるご質問" />
      <Wrapper>
        <OfficialLowHeader
          label="よくあるご質問"
          description="キンクラに関する主要なご質問をまとめました"
        />
        <OfficialLowFaq01 />
        <div className="p-top-data-wrap">
          <p>
            キンクラをご利用中に困った時は、こちらのヘルプページをご確認ください。
          </p>
          <OfficialCommonBtn
            size="small"
            level="weak"
            onClick={() => {
              window.open(routeParam)
            }}
          >
            ヘルプ
          </OfficialCommonBtn>
        </div>
        <OfficialGalleryBtnSet />
      </Wrapper>
    </OfficialLayout>
  )
}

export default ServiceFaqPage

export const pageQuery = graphql`
  query ServiceFaqPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
