import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialFaqItems from '../molecules/officialFaqItems'

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0;
    margin: 0 auto;
    .p-section-function {
      width: 100%;
      margin: 0 auto 20px;
      text-align: center;
    }
  }
  ${BreakPoints.largest} {
  }
  ${BreakPoints.medium} {
    .p-section-inner {
      max-width: calc(100vw - 40px);
      padding: 40px 0;
      .p-section-function {
        width: 100%;
        margin: 0 auto 10px;
      }
    }
  }
`

/**
 * サービスサイト下層よくあるご質問01
 * @constructor
 */
const OfficialLowFaq01 = () => (
  <Wrapper>
    <div className="p-section-inner">
      <div className="p-section-faq">
        <OfficialFaqItems
          question="試しに使ってみたいのですが、デモサイトはありますか"
          answer={
            '無料トライアルからアカウントを作成していただくと、すぐに無料で利用が可能です。\nユーザー数２０名以下の企業様は永久無料となり、費用発生の心配はございません。ユーザー権限にいくつかの種類がございますので、複数のユーザー様でのご利用をおすすめいたします。'
          }
          className="is-show"
          idName="matter-01"
        />
        <OfficialFaqItems
          question="給与ソフトへの連携は対応していますか"
          answer="はい、対応しております。エクスポートの際、どのデータをエクスポートするのかを選択可能ですので、導入されている給与ソフトに合わせてエクスポートcsvを出力できます。"
          idName="matter-02"
        />
        <OfficialFaqItems
          question="社員データを一括で登録したいのですが、可能ですか"
          answer="CSVでのインポートが可能です。大量の社員データを更新する場合もインポートにて一括変更が可能です。"
          idName="matter-03"
        />
        <OfficialFaqItems
          question="利用するためには、どのような環境が必要ですか"
          answer={' '}
          idName="matter-04"
        >
          キンクラを快適にご利用いただくために、以下の環境でのご利用を推奨しております。
          <br />
          また、JavaScript、Cookieが使用できる状態でご利用ください。
          <h3>WEB版の推奨環境</h3>
          <h4>Windows OSをご利用の場合</h4>
          ・Google Chrome最新版
          <br />
          ・Mozilla Firefox最新版
          <br />
          ・Microsoft Edge最新版
          <h4>Mac OSをご利用の場合</h4>
          ・Safari最新版
          <br />
          ・Google Chrome最新版
          <h4>iOSをご利用の場合</h4>
          ・iOS 13以降の標準ブラウザ最新版
          <h4>AndroidOS </h4>
          ・Android 9.0以降の標準ブラウザ最新版
          <h3>アプリ版の推奨環境</h3>
          <h4>iOS端末（iPhoneなど）をご利用の場合</h4>
          ・iOS 13.0以上
          <br />
          ・iPhone 8以降
          <br />
          ・iPad 第8世代、iPad mini 第5世代以降
          <h4>Android端末をご利用の場合</h4>
          ・Android 9.0以上
          <br />
          ・メモリ(RAM)：3GB 以上搭載
        </OfficialFaqItems>
        <OfficialFaqItems
          question="料金の支払い方法にはどんなものがありますか"
          answer="クレジットカードでのお支払いが可能です。"
          idName="matter-05"
        />
      </div>
    </div>
  </Wrapper>
)

export default OfficialLowFaq01
